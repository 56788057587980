
import { mapGetters } from 'vuex';

const defaultOptions = {
  // we will display a bottom sheet in the mobile size. you can disable this behavior by setting this option to true.
  modalOnly: false,
  // the HTML body will be lost its scroll behavior if this option be true
  disableBodyScroll: true,
  backdropZIndex: 'z-[1000]',
  backdropClass: null,
  wrapperClass: null,
  bodyClass: null,
  contentClass: null,
  closeButtonClass: null,
  isBooksRoute: false,
};

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'default',
    },
    component: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    baseWrapper: null,
    isVisible: false,
  }),

  computed: {
    ...mapGetters('ui', ['device']),
    layoutComponentName() {
      return `jw-modal-layout-${this.layout}`;
    },
    optionsWithDefaultValues() {
      return { ...defaultOptions, ...this.options };
    },
    thisComponent() {
      return this;
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(show) {
        this.toggleBodyScroll(show);
        this.isVisible = show;
      },
    },
    isVisible(visible) {
      if (visible) {
        this.onModalVisible();
      } else {
        this.onModalDisappear();
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.isBooksRoute = this.$route.path.startsWith('/books/') ?? false;
      },
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
      this.$emit('update:show', false);
      // this emit tell to the parent that user closed this modal
      this.$emit('closed');
    },
    closeIfPossible() {
      if (this.closable) {
        this.close();
      } else {
        // this emit tell to the parent that this modal can not close itself but the user wants to close it
        // so the parent component can handle it by displaying a warning or a confirmation modal
        this.$emit('close');
      }
    },
    setupContainer() {
      this.baseWrapper = document.querySelector('.jw-modal');
      if (!this.baseWrapper) {
        this.baseWrapper = document.createElement('div');
        this.baseWrapper.className = 'jw-modal';
        document.body.appendChild(this.baseWrapper);
      }
    },
    setupBodyOverflow() {
      if (this.optionsWithDefaultValues.disableBodyScroll) {
        document.body.classList.add('jw-modal-opened');
      }
    },
    removeBodyOverflow() {
      if (this.optionsWithDefaultValues.disableBodyScroll) {
        // if just one modal has been opened
        if (this.baseWrapper.children.length === 1) {
          // this setTimeout help us to make sure the modal is closed and then the body overflow will be removed.
          // before this we will see two scrollbar during the closing the modal
          setTimeout(() => {
            document.body.classList.remove('jw-modal-opened');
          }, 350);
        }
      }
    },
    appendToDOM() {
      this.baseWrapper.appendChild(this.$el);
    },
    removeElement(el) {
      if (typeof el.remove !== 'undefined') {
        el.remove();
      } else {
        el.parentNode.removeChild(el);
      }
    },
    onModalVisible() {
      this.setupContainer();
      this.setupBodyOverflow();
      this.appendToDOM();
      document.addEventListener('keyup', this.onKeyup);
    },
    onModalDisappear() {
      this.$nextTick(() => {
        this.removeElement(this.$el);
        this.removeBodyOverflow();
        document.removeEventListener('keyup', this.onKeyup);
      });
    },
    onKeyup(event) {
      if (event.code === 'Escape') {
        const indexOfThisModal = Array.from(this.baseWrapper.children).indexOf(this.$el);
        const numberOfOpenedModals = Array.from(this.baseWrapper.children).length;
        if (indexOfThisModal + 1 === numberOfOpenedModals) {
          this.closeIfPossible();
        }
      }
    },
    toggleBodyScroll(show) {
      // Early return if no base wrapper
      const htmlElement = document.documentElement;
      const children = Array.from(this.baseWrapper?.children || []);
      const modalIndex = children.indexOf(this.$el);
      const openModalsCount = children.length;

      // Helper to check scroll state
      const isScrollLocked = htmlElement.classList.contains('no-scroll');

      // Don't add scroll lock if already locked
      if (show && isScrollLocked) {
        return;
      }

      // Cases to handle scroll toggling
      const shouldAddScroll = show && modalIndex === -1 && openModalsCount === 0;
      const shouldRemoveScroll = !show && modalIndex >= 0 && openModalsCount === 1;

      // Apply scroll toggle
      if (shouldRemoveScroll) {
        htmlElement.classList.remove('no-scroll');
      } else if (shouldAddScroll) {
        htmlElement.classList.add('no-scroll');
      }
    },
  },
};
