export const configs = (env) => ({
  // Date
  defaultDate: 'YYYY-MM-DD',

  // player
  player: {
    options: {
      controls: [
        'play-large',
        'restart',
        'rewind',
        'play',
        'fast-forward',
        'progress',
        'duration',
        'mute',
        'volume',
        'settings',
        'pip',
        'fullscreen',
      ],
      vimeo: {
        loop: false,
      },
    },
  },

  errors: {
    general_error: 'Something went wrong. Please reload and try again.',
  },

  // Stripe style
  stripeStyle: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },

  // in milliseconds
  resendTimer: 110000,

  chatBox: {
    chat_box_message: "Welcome to Jack Westin! I'm here to help if you need anything just click on the chat any time",
    chat_box_title: env.MCAT_TUTOR_TITLE ?? 'Jack Westin Support',
    chat_box_iframe: env.MCAT_TUTOR_URL ?? 'https://mcatsciencetutor.replit.app',
  },

  carsCourses: String(env.COURSES_WITH_ACCESS_TO_CARS_MATERIAL ?? '4,12')
    .split(',')
    .map((course) => Number(course.trim())),

  homePageBanner: {
    catLink:
      env.LANDING_BANNER_CTA_URL ||
      'https://calendly.com/academic-advising-4/15-minute-account-setup-5-day-free-trial-1?utm_campaign=website&utm_source=frontpage',
    watchLink: env.LANDING_BANNER_WATCH_URL,
  },

  // event bus
  event: {
    player: {
      toggle_sidebar: 'player_toggle_sidebar',
      is_sidebar_open: 'player_is_sidebar_open',
    },
    sidebar: {
      toggle_sidebar: 'sidebar_toggle_sidebar',
      enable_toggle: 'sidebar_enable_toggle',
    },
    session: {
      toggle_session_enrollment: 'toggle_session_enrollment',
      sessions_page_tab_change: 'sessions_page_tab_change',
    },
    search: {
      search_done: 'search_done',
    },
    show_login: 'show_login',
    enable_player_progress: 'enable_player_progress',
    enroll_to_session: 'enroll_to_session',
    logged_in: 'logged_in',
    show_trial_session_dialog: 'show_trial_session_dialog',
    subscribe_clicked: 'subscribe_clicked',
    unsubscribe_clicked: 'unsubscribe_clicked',
    show_verify_mobile_modal: 'show_verify_mobile_modal',
    chain_succeeded: 'chain_succeeded',
    verified_mobile_and_test_date: 'verified_mobile_and_test_date',
    quiz: {
      layout: {
        reset: 'quiz_layout_reset',
        remove_section: 'quiz_layout_remove_section',
      },
      close_timer_modals: 'quiz_close_timer_modals',
      highlight: 'quiz_highlight_annotate',
    },
    toggle_cookie_banner: 'toggle_cookie_banner',
    show_external_link_dialog: 'show_external_link_dialog',
  },
  freeTrial: {
    advisorUrl: env.CALENDLY_FREE_TRIAL_ENDED ?? 'https://calendly.com/d/ck6j-yxq-4f3',
  },
  phoneNumber: '4158554435',
  examSurveyLink: env.EXAM_SURVEY_LINK ?? 'https://forms.gle/rLCDASfRLFd9c62L7',
  homePageMaxSessionsCount: env.HOME_PAGE_MAX_SESSIONS_COUNT ?? 6,
  typesense: {
    host: env.TYPESENSE.HOST ?? '',
    apiKey: env.TYPESENSE.API_KEY ?? '',
    port: env.TYPESENSE.PORT ?? '',
    protocol: env.TYPESENSE.PROTOCOL ?? '',
    timeout: env.TYPESENSE.TIMEOUT ?? '',
  },
  libraryTutorTips: {
    name: env.TUTOR_TIPS_BLOCK.NAME || 'John Doe',
    ctaLink: env.TUTOR_TIPS_BLOCK.CTA_LINK || 'https://calendly.com/d/ck6j-yxq-4f3',
    ctaText: env.TUTOR_TIPS_BLOCK.CTA_TEXT || 'Book a Tutoring Session with John',
    avatar:
      env.TUTOR_TIPS_BLOCK.AVATAR ||
      'https://www.figma.com/file/r22Tf3LE6ym75oGXhH7MV7/image/758357beae9594f1160471db992db1cf36ca3f46',
  },
});
