import { DRAWER_SESSION_KEY } from '~/core/utils/constants';

export const defaultState = () => ({
  book: {},
  headings: [],
  nestedData: [],
  sectionSlug: null,
  flattenSections: [],
  fontSettings: {
    families: [
      {
        id: 1,
        label: 'Georgia',
        className: 'font-georgia',
      },
      {
        id: 2,
        label: 'Inter',
        className: 'font-inter',
      },
      {
        id: 3,
        label: 'Open Sans',
        className: 'font-open-sans',
      },
      {
        id: 4,
        label: 'Roboto',
        className: 'font-roboto',
      },
    ],
    sizes: [
      {
        id: 1,
        label: 'Small',
        value: 'small',
      },
      {
        id: 2,
        label: 'Default',
        value: 'default',
      },
      {
        id: 3,
        label: 'Large',
        value: 'large',
      },
    ],
    activeFamilyId: 2,
    activeSizeId: 2,
  },
  landingSettings: {},
  drawerSession: sessionStorage.getItem(DRAWER_SESSION_KEY) || false,
});

export default () => defaultState();
